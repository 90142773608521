import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../../utils/ApiTools';

export const fetchJobRateCodes = createAsyncThunk(
  'jobRateCodes/fetchJobRateCodes',
  async ({ jobRouteId, companyId }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/job_routes/${jobRouteId}/job_rate_codes`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addMinimalJobRateCode = createAsyncThunk(
  'jobRateCodes/addMinimalJobRateCode',
  async ({ jobRouteId, companyId, appCodeData }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/job_routes/${jobRouteId}/job_rate_codes/create_from_minimal`,
        {
          method: 'POST',
          body: JSON.stringify({
            job_rate_code: appCodeData
          }),
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const denyJobRateCode = createAsyncThunk(
  'jobRateCodes/denyJobRateCode',
  async ({ jobRouteId, companyId, appCodeId, denyNote }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/job_routes/${jobRouteId}/job_rate_codes/${appCodeId}/deny`,
        {
          method: 'POST',
          body: JSON.stringify({ job_rate_code: { deny_note: denyNote } }),
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const denyAllJobRateCode = createAsyncThunk(
  'jobRateCodes/denyAllJobRateCode',
  async ({ jobRouteId, companyId, denyNote }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/job_routes/${jobRouteId}/job_rate_codes/deny_all`,
        {
          method: 'POST',
          body: JSON.stringify({ job_rate_code: { deny_note: denyNote } }),
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const approveAllJobRateCode = createAsyncThunk(
  'jobRateCodes/approveAllJobRateCode',
  async ({ jobRouteId, companyId }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/job_routes/${jobRouteId}/job_rate_codes/approve_all`,
        {
          method: 'POST'
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editJobRateCode = createAsyncThunk(
  'jobRateCodes/editJobRateCode',
  async ({ jobRouteId, companyId, rateCodeId, rateCodeData }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/job_routes/${jobRouteId}/job_rate_codes/${rateCodeId}`,
        {
          method: 'PUT',
          body: JSON.stringify({ job_rate_code: rateCodeData }),
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteJobRateCode = createAsyncThunk(
  'jobRateCodes/deleteJobRateCode',
  async ({ jobRouteId, companyId, appCodeId }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/job_routes/${jobRouteId}/job_rate_codes/${appCodeId}`,
        {
          method: 'DELETE',
        }
      );

      return appCodeId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const jobRateCodesSlice = createSlice({
  name: 'jobRateCodes',
  initialState: {
    rateCodes: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobRateCodes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchJobRateCodes.fulfilled, (state, action) => {
        state.loading = false;
        state.rateCodes = action.payload;
      })
      .addCase(fetchJobRateCodes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(addMinimalJobRateCode.fulfilled, (state, action) => {
        state.rateCodes.push(action.payload);
      })

      .addCase(editJobRateCode.fulfilled, (state, action) => {
        const index = state.rateCodes.findIndex(
          (rateCode) => rateCode.id === action.payload.id
        );
        if (index !== -1) {
          state.rateCodes[index] = action.payload;
        }
        state.loading = false;
      })

      .addCase(denyJobRateCode.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(denyJobRateCode.fulfilled, (state, action) => {
        const index = state.rateCodes.findIndex(
          (rateCode) => rateCode.id === action.payload.id
        );
        if (index !== -1) {
          state.rateCodes[index] = action.payload;
        }
        state.loading = false;
      })
      .addCase(denyJobRateCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteJobRateCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteJobRateCode.fulfilled, (state, action) => {
        state.rateCodes = state.rateCodes.filter(
          (rateCode) => rateCode.id !== action.payload
        );
        state.loading = false;
      })
      .addCase(deleteJobRateCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(denyAllJobRateCode.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(denyAllJobRateCode.fulfilled, (state, action) => {
        state.rateCodes = state.rateCodes.map(rateCode => {
          rateCode.status = 'denied'
          return rateCode;
        })
        state.loading = false;
      })
      .addCase(denyAllJobRateCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(approveAllJobRateCode.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(approveAllJobRateCode.fulfilled, (state, action) => {
        state.rateCodes = state.rateCodes.map(rateCode => {
          rateCode.status = 'approved'
          return rateCode;
        })
        state.loading = false;
      })
      .addCase(approveAllJobRateCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export default jobRateCodesSlice.reducer;
