import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import 'tailwindcss/tailwind.css';
import SearchJobsBtn from './components/buttons/SearchJobsBtn';
import BillJobsBtn from './components/buttons/BillJobsBtn';
import GenerateExportsBtn from './components/buttons/GenerateExportsBtn';
import HoursApprovalBtn from './components/buttons/HoursApprovalBtn';
import TechPayReportBtn from './components/buttons/TechPayReportBtn';
import BillingComparisonReportBtn from './components/buttons/BillingComparisonReportBtn';
import LockJobsBtn from './components/buttons/LockJobsBtn';
import ViewJobsBtn from './components/buttons/ViewJobsBtn';
import ManageCodesBtn from './components/buttons/ManageCodesBtn';

import HeaderTitleBar from 'components/HeaderTitleBar';

const Billing = () => {
  const location = useLocation();
  const isRootPath = location.pathname === '/billing';

  const btnTheme = {
    btnClassName: `flex items-center
      bg-blue-600 text-white px-3 py-1 hover:bg-blue-500 text-white rounded-sm shadow-md
      focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75
    `,
    icoClassName: "h-4 w-4 text-white-500",
  }

  return (
    <>
     {isRootPath && (
        <>
          <div className="p-4 mb-6 bg-white shadow rounded-lg">
            <HeaderTitleBar
              title='Billing'
              showBtn={false}
            />
          </div>
          <div className="p-4 bg-white shadow rounded-lg">
            <div className="mt-4 grid grid-cols-2 gap-4">
              <SearchJobsBtn btnTheme={btnTheme} />
              <BillJobsBtn btnTheme={btnTheme} />
              <ViewJobsBtn btnTheme={btnTheme} />
              <GenerateExportsBtn btnTheme={btnTheme} />
              <HoursApprovalBtn btnTheme={btnTheme} />
              <TechPayReportBtn btnTheme={btnTheme} />
              <BillingComparisonReportBtn btnTheme={btnTheme} />
            </div>
            <div className="mt-10 grid grid-cols-2 gap-4">
              <LockJobsBtn btnTheme={btnTheme} />
              <ManageCodesBtn btnTheme={btnTheme} />
            </div>
          </div>
        </>
     )}

     <Outlet />
    </>
  );
};

export default Billing;