import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchWithAuth } from '../../utils/ApiTools'

const initialState = {
  techCollection: [],
  techResource: null,
  loading: false,
  pageCount: 0,
  totalItems: 0,
  pageSize: 25,
  success: false
};

const errorHandler = (error) => {
  return error.response?.data?.message || error.message || 'Something went wrong';
}

export const fetchTechnicians = createAsyncThunk('technicians/all',
    async ({
      page = 1,
      per = 25,
      search = '',
      sorting = [],
      navigate
    }) => {
  const msoId = localStorage.getItem('msoId');
  let queryParams = new URLSearchParams({
    page: page,
    per: per,
  });

  if (search) {
    queryParams.append('search', search)
  }

  let sortBy = '';
  let direction = '';
  const sortField = sorting.find(s => s.selected);

  sortBy = sortField ? sortField.id : 'id';
  direction = sortField ? (sortField.desc ? 'desc' : 'asc') : 'asc';

  if (sortBy) {
    queryParams.append('sort_by', sortBy);
    queryParams.append('direction', direction);
  }

  const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/technicians?${queryParams.toString()}`

  return fetchWithAuth(url, {}, navigate);
});

export const fetchTechnician = createAsyncThunk(
  'technician/fetchTechnician',
  async (techId, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/technicians/${techId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(errorHandler(error));
    }
  }
);

export const fetchTechList = createAsyncThunk(
  'technician/fetchTechList',
  async ({
      companyId,
      areaId
    }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    let queryParams = new URLSearchParams();

    if (companyId) {
      queryParams.append('company_id', companyId);
    }

    if (areaId) {
      queryParams.append('area_id', areaId);
    }

    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/technicians/list?${queryParams.toString()}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(errorHandler(error));
    }
  }
);


export const editTechnician = createAsyncThunk(
  'technician/editTechnician',
  async ({ techId, formData, navigate }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/technicians/${techId}`,
        {
          method: 'PUT',
          body: JSON.stringify({ technician: formData }),
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(errorHandler(error));
    }
  }
);

export const addTechnician = createAsyncThunk(
  'technician/addTechnician',
  async (formData, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/technicians`,
        {
          method: 'POST',
          body: JSON.stringify({ technician: formData }),
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(errorHandler(error));
    }
  }
);


const techniciansSlice = createSlice({
  name: 'technician',
  initialState,
  reducers: {
    clearTechnicians: () => {
      return initialState;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSelectedCompanyId: (state, action) => {
      state.selectedCompanyId = action.payload;
    },
    setSelectedAreaId: (state, action) => {
      state.selectedAreaId = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchTechnicians.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(fetchTechnicians.fulfilled, (state, action) => {
      state.loading = false;
      state.techCollection = action.payload.data;
      state.pageCount = action.payload.meta.pagination.total_pages;
      state.totalItems = action.payload.meta.pagination.total_items;
      state.error = null;
    })
    .addCase(fetchTechnicians.rejected, (state, action) => {
      state.loading = false;
      state.techCollection = [];
      state.error = action.error.message;
    })

    .addCase(fetchTechnician.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchTechnician.fulfilled, (state, action) => {
      state.loading = false;
      state.techResource = action.payload;
    })
    .addCase(fetchTechnician.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(editTechnician.pending, (state) => {
      state.loading = true;
      state.success = false
      state.error = null;
    })
    .addCase(editTechnician.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true
      state.techData = action.payload;
    })
    .addCase(editTechnician.rejected, (state, action) => {
      state.loading = false;
      state.success = false
      state.error = action.payload;
    })
    .addCase(addTechnician.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = null;
    })
    .addCase(addTechnician.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true
      state.techCollection.push(action.payload)
    })
    .addCase(addTechnician.rejected, (state, action) => {
      state.loading = false;
      state.success = false
      state.error = action.payload;
    })
    .addCase(fetchTechList.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(fetchTechList.fulfilled, (state, action) => {
      state.techCollection = action.payload.sort((a, b) => (a.first_name > b.first_name) ? 1 : -1);
      state.loading = false;
      state.error = null;
    })
    .addCase(fetchTechList.rejected, (state, action) => {
      state.loading = false;
      state.techCollection = [];
      state.error = action.error.message;
    });
  }
});


export const {
  clearTechnicians,
  clearFormData,
  setPageSize,
  setSelectedCompanyId,
  setSelectedAreaId,
  setSuccess,
  setError
} = techniciansSlice.actions;
export default techniciansSlice.reducer;