import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import HeaderTitleBar from 'components/HeaderTitleBar';
import { selectAuthUser } from '../../features/auth/authUserSlice';
import 'tailwindcss/tailwind.css';

import FormDatePicker from 'components/formHelpers/FormDatePicker';
import AreaSelectBox from 'components/dropdowns/AreaSelectBox';
import { fetchCompanies } from '../../features/companies/companiesSlice';
import SelectBox from '../fields/SelectBox';

import {
  setSelectedCompanyId,
  setSelectedAreaId,
  setSelectedUserTime,
  fetchHourlyApproval
 } from 'features/billing/hourlyApprovalSlice';

const HoursApproval = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = useSelector(selectAuthUser);
  const authUserRole = authUser.role;
  const [selectedEntryDate, setSelectedEntryDate] = useState(new Date());

  const {
    hourlyApprovalCollection,
    selectedCompanyId,
    selectedAreaId,
  } = useSelector((state) => state.hourlyApproval);


  const { companyCollection } = useSelector((state) => state.companies);

  const canSelectCompany = () => {
    return /admin|mso_user/.test(authUserRole);
  }

  const fetchCompaniesData = useCallback(() => {
    dispatch(fetchCompanies({ page: 1, per: 9999, navigate }));
  }, [ dispatch, navigate]);

  useEffect(() => {
    if (!canSelectCompany()) return;

    fetchCompaniesData();
  }, [ fetchCompaniesData, dispatch ])

  const handleSelectedCompanyId = (value) => {
    dispatch(setSelectedCompanyId(value));
  };

  const handleAreaChange = (e) => {
    const { value } = e.target;
    dispatch(setSelectedAreaId(value));
  }

  const handleEntryDateChange = (e) => {
    setSelectedEntryDate(e);
  }

  const handleSubmit = () => {
    const date =  moment(selectedEntryDate).format('YYYY-MM-DD');
    if (typeof(selectedCompanyId) === 'undefined' ||  parseInt(selectedCompanyId) === 0) {
      return;
    }

    dispatch(fetchHourlyApproval({
      companyId: selectedCompanyId,
      areaId: selectedAreaId,
      entryDate: date
    }));
  }

  const weekDayText = (day) => {
    return `${day.total_hours}`;
  }

  const editBtn = (timeData, user_id) => {
    const svgIco = (
      <svg
        className={`h-6 w-6 ${timeData['approved'] ? 'text-blue-600' : 'text-blue-500'}`}
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        key={`${user_id}-editSvg`}>
          <path stroke="none" d="M0 0h24v24H0z"/>
          <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
          <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
          <line x1="16" y1="5" x2="19" y2="8" />
        </svg>
    )
    const formattedDate = moment(selectedEntryDate).format('YYYY-MM-DD');
    const url=`/billing/time_keeper?userId=${user_id}&areaId=${selectedAreaId}&companyId=${selectedCompanyId}&entryDate=${formattedDate}`;

    return (
      <a href={url} onClick={(e) => {
        e.preventDefault();
        navigate(url);
      }}>
        {svgIco}
      </a>
    )
  }

  const fillRows = () => {
    if (!hourlyApprovalCollection || hourlyApprovalCollection.length === 0) {
      return (
        <tr key={'emptyRow'}>
          <td colSpan={10}>...</td>
        </tr>
      )
    }

    const techs = Object.keys(hourlyApprovalCollection.data)
    return techs.map((user_id, index) => {
      const timeData = hourlyApprovalCollection.data[user_id];

      return (
        <>
          <tr key={user_id} className={`text-sm text-gray h-10 ${timeData['approved'] && 'bg-green-400 text-black border-b-2'}`}>
            <td className='p-1 pl-2 w-1/2'>{timeData.user_name}</td>
            <td className='p-1 pl-2 w-100'>{weekDayText(timeData.monday)}</td>
            <td className='p-1 pl-2 w-100'>{weekDayText(timeData.tuesday)}</td>
            <td className='p-1 pl-2 w-100'>{weekDayText(timeData.wednesday)}</td>
            <td className='p-1 pl-2 w-100'>{weekDayText(timeData.thursday)}</td>
            <td className='p-1 pl-2 w-100'>{weekDayText(timeData.friday)}</td>
            <td className='p-1 pl-2 w-100'>{weekDayText(timeData.saturday)}</td>
            <td className='p-1 pl-2 w-100'>{weekDayText(timeData.sunday)}</td>
            <td className='p-1 pl-2 w-100'>{timeData.total_hours}</td>
            <td className='p-1 pl-2 w-100'>{editBtn(timeData, user_id)}</td>
          </tr>
        </>
      )
    })
  }

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title='Hours Approval'
          showBtn={false}
        />

        {canSelectCompany() && (
          <div className="mt-4">
            <SelectBox
              data={companyCollection}
              defaultValue={selectedCompanyId}
              onChange={handleSelectedCompanyId}
              valueField={'id'}
              keyField={'name'}
              zeroOption={'Select a Company (All)'}
            />
        </div>
        )}
      </div>
      <div className="p-4 mb-6 bg-white shadow rounded-lg sm:w-full">
        <div className="md:grid md:w-1/6 justift-items-start grid-cols-1 gap-4 mt-4">
            <FormDatePicker
              label={'Week Ending Date'}
              name="we_date"
              onChange={handleEntryDateChange}
              placeholder="Select a Date"
              value={selectedEntryDate}
              horizontal={false}
            />
        </div>
        <div className="md:grid md:w-1/6 justift-items-start grid-cols-1 gap-2 mt-4">
            <AreaSelectBox
              label={'Area'}
              name={'area_id'}
              value={selectedAreaId}
              onChange={handleAreaChange}
              useTarget={false}
              horizontal={false}
            />
        </div>
        <div className="grid w-1/6 justift-items-start grid-cols-1 gap-2 mt-4">
          <button onClick={handleSubmit} className="w-40 bg-blue-500 h-9 text-white font-semibold rounded-sm hover:bg-blue-700">
            View
          </button>
        </div>
      </div>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <table className="w-full divide-y divide-gray-200 mt-1">
          <thead className="bg-gray-50">
            <tr>
              <th className=' py-3 text-left text-xs font-medium text-gray-500 uppercase'>Tech</th>
              <th className=' py-3 text-left text-xs font-medium text-gray-500 uppercase'>Mon</th>
              <th className=' py-3 text-left text-xs font-medium text-gray-500 uppercase'>Tues</th>
              <th className=' py-3 text-left text-xs font-medium text-gray-500 uppercase'>Wed</th>
              <th className=' py-3 text-left text-xs font-medium text-gray-500 uppercase'>Thur</th>
              <th className=' py-3 text-left text-xs font-medium text-gray-500 uppercase'>Fri</th>
              <th className=' py-3 text-left text-xs font-medium text-gray-500 uppercase'>Sat</th>
              <th className=' py-3 text-left text-xs font-medium text-gray-500 uppercase'>Sun</th>
              <th className=' py-3 text-left text-xs font-medium text-gray-500 uppercase'>Total</th>
              <th className=' py-3 text-left text-xs font-medium text-gray-500 uppercase'>Options</th>
              </tr>
          </thead>
          <tbody>
            {hourlyApprovalCollection && fillRows()}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HoursApproval;