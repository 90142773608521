import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../../utils/ApiTools';

const initialState = {
  data: [],
  history: [],
  meta: {},
  loading: false,
  addInventoryLoading: false,
  pageCount: 1,
  totalItems: 0,
  currentPage: 1,
  nextPage: null,
  prevPage: null,
  pageSize: 25,
  searchQuery: ''
};

export const fetchInventories = createAsyncThunk(
  'inventories/all',
  async ({ page = 1, per = 25, search = '', sorting = [], navigate }) => {
    const msoId = localStorage.getItem('msoId');

    let sortBy = '';
    let direction = '';

    const sortField = sorting.find(s => s.selected);
    sortBy = sortField ? sortField.id : 'id';
    direction = sortField ? (sortField.desc ? 'desc' : 'asc') : 'asc';

    const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/inventories?page=${page}&per=${per}&search=${search}&sort_by=${sortBy}&direction=${direction}`;

    return fetchWithAuth(url, {}, navigate);
  }
);

export const fetchHistory = createAsyncThunk(
  'inventories/history',
  async ({ inventoryId, navigate }) => {
    const msoId = localStorage.getItem('msoId');
    const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/inventories/${inventoryId}/inventory_histories`;
    return fetchWithAuth(url, {}, navigate);
  }
);

export const addInventory = createAsyncThunk(
  'inventories/addInventory',
  async ({ formData, meta }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/inventories/add_inventory`;
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({
          inventory: formData,
          meta: meta
        }),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const transferInventory = createAsyncThunk(
  'inventories/transferInventory',
  async ({ formData, meta }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/inventories/transfer_inventory`;
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({
          inventory: formData,
          meta
        }),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const issueInventory = createAsyncThunk(
  'inventories/issueInventory',
  async ({ formData, meta }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/inventories/issue_inventory`;
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({
          inventory: formData,
          meta: meta
        }),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const returnInventory = createAsyncThunk(
  'inventories/returnInventory',
  async ({ formData }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/inventories/return_inventory`;
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({
          inventory: formData
        }),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



const inventorySlice = createSlice({
  name: 'inventories',
  initialState,
  reducers: {
    clearInventories: () => initialState,
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInventories.pending, (state) => {
      state.success = false
      state.error = false;
      state.loading = true;
    })
    .addCase(fetchInventories.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;

      if (action.payload.meta) {
        const pagination = action.payload.meta.pagination;
        state.pageCount = pagination.total_pages;
        state.totalItems = pagination.total_items;
        state.currentPage = pagination.current_page;
        state.prevPage = pagination.prev_page;
        state.nextPage = pagination.next_page;
      }

      state.error = null;
    })
    .addCase(fetchInventories.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    })
    .addCase(fetchHistory.pending, (state) => {
      state.success = false
      state.error = false;
      state.loading = true;
    })
    .addCase(fetchHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.history = action.payload.data;
      state.error = null;
    })
    .addCase(fetchHistory.rejected, (state, action) => {
      state.loading = false;
      state.history = [];
      state.success = false;
      state.error = action.error.message;
    })
    .addCase(addInventory.pending, (state) => {
      state.addInventoryLoading = true;
    })
    .addCase(addInventory.fulfilled, (state, action) => {
      state.addInventoryLoading = false;

    })
    .addCase(addInventory.rejected, (state, action) => {
      state.addInventoryLoading = false;
      state.error = action.payload;
    })
    .addCase(returnInventory.pending, (state) => {
      state.loading = true;
    })
    .addCase(returnInventory.fulfilled, (state, action) => {
      state.loading = false;

    })
    .addCase(returnInventory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(transferInventory.pending, (state) => {
      state.loading = true;
    })
    .addCase(transferInventory.fulfilled, (state, action) => {
      state.loading = false;

    })
    .addCase(transferInventory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const {
  clearInventories,
  setPageSize,
  setSearchQuery,
} = inventorySlice.actions;

export default inventorySlice.reducer;
