import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../../utils/ApiTools';

const initialState = {
  loading: false,
  error: null,
};

export const fetchJobRouteById = createAsyncThunk(
  'jobRoute/fetchById',
  async ({ jobRouteId, navigate }) => {
    const msoId = localStorage.getItem('msoId');
    const companyId = localStorage.getItem('companyId');

    const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/job_routes/${jobRouteId}`;
    return fetchWithAuth(url, {}, navigate);
  }
);

export const editJobRoute = createAsyncThunk(
  'jobRouteNotes/editBillingStatus',
  async ({ jobRouteId, jobRouteParams }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
    const companyId = localStorage.getItem('companyId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/job_routes/${jobRouteId}`;
      const response = await fetchWithAuth(url, {
        method: 'PUT',
        body: JSON.stringify({
          job_route: jobRouteParams
        }),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const jobRouteSlice = createSlice({
  name: 'jobRoute',
  initialState,
  reducers: {
    clearJobRoute: (state) => {
      state.data = null;
      state.error = null;
    },
    selectedCompanyId: () => {
      localStorage.getItem('companyId');
    },
    selectedAreaId: () => {
      localStorage.getItem('areaId');
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchJobRouteById.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchJobRouteById.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.error = null;
    })
    .addCase(fetchJobRouteById.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.error = action.error.message;
    })
    .addCase(editJobRoute.pending, (state) => {
      state.loading = true;
    })
    .addCase(editJobRoute.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = false;
    })
    .addCase(editJobRoute.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.error = action.error.message;
    });
  },
});

export const {
  selectedCompanyId,
  selectedAreaId,
  clearJobRoute
} = jobRouteSlice.actions;

export default jobRouteSlice.reducer;