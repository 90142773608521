import React, { useEffect, useCallback }  from 'react';
import { useDispatch } from 'react-redux';
import 'tailwindcss/tailwind.css';
import BillingUploader from './components/BillingUploader';
import { fetchMsos } from '../../features/msos/msosSlice';
import HeaderTitleBar from 'components/HeaderTitleBar';

const BillJobs = () => {
  const dispatch = useDispatch();
  const fetchMsosData = useCallback(() => {
    dispatch(fetchMsos({ page: 1, per: 9999 }));
  }, [dispatch]);

  useEffect(() => {
    fetchMsosData();
  }, [fetchMsosData]);

  return (
    <>

      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title='Imports Jobs from MSO'
          showBtn={false}
        />
      </div>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        {localStorage.getItem('msoId') === '4' ? (
          <BillingUploader bucket="focis" label="Focis" types=".xlsx" />
        ):(
          <>
            <BillingUploader bucket="wfxexports" label="WFX Export" types=".xlsx, .xml" />
            <BillingUploader bucket="wfxcheckins" label="WFX Checkin" types=".xlsx, .xml" />
            <BillingUploader bucket="wfxeow" label="End of Week Invoice" types=".xlsx, .xml" />
          </>
        )}
      </div>
    </>
  );
};

export default BillJobs;