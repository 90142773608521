import React, { useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  fetchJobRouteById,
  editJobRoute,
  clearJobRoute
} from '../../features/jobRoutes/jobRouteSlice';

import JobDetailsNotes from './components/JobDetailNotes';
import JobDetailsAppCodes from './components/JobDetailsAppCodes';
import JobRouteBillingStatusBtn from './components/buttons/JobRouteBillingStatusBtn';
import EditableTextField from '../fields/EditableTextField';
import EditableDateField from '../fields/EditableDateField';
import { titlize } from 'utils/TextTools';
import HeaderTitleBar from 'components/HeaderTitleBar';
import Postcall from './components/Postcall';

import 'tailwindcss/tailwind.css';

const JobDetails = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: jobRoute, loading, error } = useSelector((state) => state.jobRoute);

  useEffect(() => {
    dispatch(fetchJobRouteById({ jobRouteId: jobId, navigate }));

    return () => {
      dispatch(clearJobRoute());
    };
  }, [dispatch, jobId, navigate]);

  const handleApprovalChange = () => {
    const newStatus = (jobRoute.billing_status === 'approved') ? 0 : 1
    dispatch(editJobRoute({ jobRouteId: jobId, jobRouteParams: { billing_status: newStatus} }));
  }

  const handleJobNumberUpdate = (job_number) => {
    dispatch(editJobRoute({ jobRouteId: jobId, jobRouteParams: { job_number: job_number} }));
  }

  const handleJobDateUpdate = (job_date) => {
    dispatch(editJobRoute({ jobRouteId: jobId, jobRouteParams: { job_date: job_date.toString()} }));
  }

  const handleBack = () => {
    navigate(-2);
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-gray-500">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-red-500">Error: {error}</div>
      </div>
    );
  }

  if (!jobRoute) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-gray-500">No job data available</div>
      </div>
    );
  }

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title={'Job Details'}
          showBtn={true}
          btnTitle={'Back'}
          btnClick={handleBack}
        />


        <div>
          <table className="w-full divide-y divide-gray-200">
            <tbody>
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Status</td>
                <td className="px-6 py-3 text-gray-500" colSpan="3">{titlize(jobRoute.status)}</td>
              </tr>
              {localStorage.getItem('msoId').toString() === '4' && (
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Corp</td>
                <td className="px-6 py-3 text-gray-500" colSpan="3">{jobRoute.corp_number}</td>
              </tr>
              )}
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Job Number</td>
                <td className="px-6 py-3 text-gray-500">
                  <EditableTextField
                    fieldValue={jobRoute.job_number}
                    btnText={'Edit Job Number'}
                    onSave={handleJobNumberUpdate} />
                </td>
                <td className="px-6 py-3 font-semibold text-gray-700">Date</td>
                <td className="px-6 py-3 text-gray-500">
                  <EditableDateField
                    fieldValue={new Date(jobRoute.job_date).toLocaleDateString()}
                    btnText={'Edit Job Date'}
                    onSave={handleJobDateUpdate} />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Technician</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute?.user?.full_name}</td>
                <td className="px-6 py-3 font-semibold text-gray-700">Address</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.address}</td>
              </tr>
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Timeframe</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.time_frame}</td>
                <td className="px-6 py-3 font-semibold text-gray-700">City</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.city}</td>
              </tr>
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Start Time</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.time_started ? new Date(jobRoute.time_started).toLocaleTimeString() : 'N/A'}</td>
                <td className="px-6 py-3 font-semibold text-gray-700">End Time</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.time_completed ? new Date(jobRoute.time_completed).toLocaleTimeString() : 'N/A'}</td>
              </tr>
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Work Type</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.job_type}</td>
                <td className="px-6 py-3 font-semibold text-gray-700">Zip</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.zip}</td>
              </tr>
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Home Phone</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.home_phone}</td>
                <td className="px-6 py-3 font-semibold text-gray-700">Other Phone</td>
                <td className="px-6 py-3 text-gray-500">{jobRoute.other_phone}</td>
              </tr>
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Billing Status</td>
                <td className="px-6 py-3 text-gray-500" colSpan="3">
                  <JobRouteBillingStatusBtn
                    status={jobRoute.billing_status}
                    handler={handleApprovalChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-3 font-semibold text-gray-700">Total Notes</td>
                <td className="px-6 py-3 text-gray-500" colSpan="3">{jobRoute.total_job_notes}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <JobDetailsNotes jobRoute={jobRoute}  />
      </div>
      {localStorage.getItem('msoId').toString() !== '4' && (
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <JobDetailsAppCodes jobRoute={jobRoute}  />
      </div>
      )}

      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <Postcall jobRoute={jobRoute}  />
      </div>
    </>
  );
};

export default JobDetails;