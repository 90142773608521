import moment from 'moment'

const formatDate = (dateStr, key) => {
  if (!dateStr) return '';
  return moment.utc(dateStr).tz("America/New_York").format('MM/DD/YYYY');
};

export const fetchWithAuth = async (url, options = {}, responseType = 'json', navigate) => {
  const token = localStorage.getItem('authToken');

  const response = await fetch(url, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      ...options.headers,
    },
  });

  if (response.status === 401) {
    const err = new Error('Unauthorized');
    err.status = 401
    throw err;
  }

  if (!response.ok) {
    const errorData = await response.json();
    if (errorData.errors) {
      const errMsg = errorData.errors[0]?.detail
      throw new Error(errMsg);
    }

    throw new Error('Network response was not ok');
  }

  if (responseType === 'blob') {
    const contentType = response.headers.get('Content-Type');

    if (contentType && contentType.includes('text/csv')) {
      return await response.blob();
    } else {
      throw new Error('Expected a Blob response but got something else.');
    }
  }

  if (response.status === 204) {
    return { data: [], meta: [] }
  }

  const result = await response.json();

  const formatData = (item) => {
    const attributes = item.attributes;

    if (typeof(attributes) === 'undefined') return item;

    return Object.fromEntries(
      Object.entries(attributes).map(([key, value]) => [
        key,
        (key === 'created_at' || key === 'updated_at' || key.includes('date')) && value
          ? formatDate(value, key)
          : value,
      ])
    );
  }

  const formattedData = (Array.isArray(result.data)) ? result.data.map(item => formatData(item)) : formatData(result.data)

  return {
    meta: result.meta,
    data: formattedData
  }
};