import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeaderTitleBar from 'components/HeaderTitleBar';
import FormTextInput from 'components/formHelpers/FormTextInput';
import TosForm from 'components/formHelpers/TosForm';
import FormStatesSelectBox from 'components/formHelpers/FormStatesSelectBox';
import FormSubmissionResult from 'components/formHelpers/FormSubmissionResult';
import FormSelectBox from 'components/formHelpers/FormSelectBox';
import { fetchUserEnums } from "features/users/usersSlice";
import CompanySelectBox from 'components/dropdowns/CompanySelectBox';
import AreaSelectBox from 'components/dropdowns/AreaSelectBox';
import VendorSelectBox from 'components/dropdowns/VendorSelectBox';
import FormDatePicker from 'components/formHelpers/FormDatePicker';

import { addUser } from 'features/users/usersSlice';
import 'tailwindcss/tailwind.css';

const CreateUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { success, error, user_types, roles, statuses } = useSelector((state) => state.users);

  const fetchEnumData = useCallback(() => {
    dispatch(fetchUserEnums());
  }, [dispatch]);

  useEffect(() => {
    fetchEnumData();
  }, [fetchEnumData]);

  const [showFor1099, setShowFor1099] = useState(false);
  const [formErrors, setFormErrors] = useState({})
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    address_1: '',
    address_2: '',
    city: '',
    state_id: 0,
    zip: '',
    user_type: 0,
    role: 0,
    status: 0,
    company_id: 0,
    area_id: 0,
    password: '',
    password_confirmation: '',
    payroll_record_attributes: {
      hire_date: '',
      vendor_id: 0,
      hourly_rate: 0,
      external_payroll_id: '',
      pay_week_offset: 0,
      pay_rate: 0,
      weekly_salary: 0,
      tax_form: 0,
    }
  });

  const validatePasswords = (errors) => {
    if (formData.password.length < 8) {
      errors.password = 'Password must be at least 8 characters, numbers, and/or special characters'
      formData.password = '';
      return errors;
    }

    if (formData.password !== formData.password_confirmation) {
      errors.password_confirmation = 'Confirmation password does not match password'
      formData.password_confirmation = '';
      return errors;
    }

    return errors;
  }

  const validateForm = () => {
    let newErrors = {};

    if (!formData.first_name) {
      newErrors.first_name = 'First Name is required';
    }

    if (!formData.last_name) {
      newErrors.last_name = 'Last Name is required';
    }

    if (!formData.email) {
      newErrors.email = 'Email is required';
    }

    newErrors = validatePasswords(newErrors);
    setFormErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newData = { ...formData };
    if (name === 'user_type' || name === 'status' || name === 'role') {
      if (value === '0') {
        newData[name] = null;
      } else {
        newData[name] = value;
      }
    } else {
      newData[name] = value;
    }

    setFormData(newData);
  };

  const handlePayRollChange = (e) => {
    const { name, value } = (e.target) ? e.target : e;

    const newData = { ...formData };
    if (name === 'tax_type') {
      if (value === '0') {
        newData.payroll_record_attributes[name] = null;
      } else {
        newData.payroll_record_attributes[name] = value;
      }
    } else {
      newData.payroll_record_attributes[name] = value;
    }

    setFormData(newData);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const scrubbedData = formData;

    if (scrubbedData.payroll_record_attributes.tax_form === "on") {
      scrubbedData.payroll_record_attributes.tax_form = 1
    }

    setFormErrors({});
    if (validateForm()) dispatch(addUser(scrubbedData))
  };

  const handleBack = () => {
    navigate('/users');
  };

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title={'Create User'}
          showBtn={true}
          btnTitle={'Back'}
          btnClick={handleBack}
        />
      </div>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <FormSubmissionResult
          successText={`User "${formData.first_name} ${formData.last_name}" Created`}
          success={success}
          error={error}
        />

        <TosForm onSubmit={handleSubmit} autoComplete={'false'} className="space-y-4 mt-6 w-1/2">
          <CompanySelectBox
            label={'Company'}
            name={'company_id'}
            value={formData.company_id}
            onChange={handleChange}
            useTarget={false}
          />

          <AreaSelectBox
            label={'Area'}
            name={'area_id'}
            value={formData.area_id}
            onChange={handleChange}
            useTarget={false}
            companyId={formData.company_id}
          />

          <div className="grid justift-items-start grid-cols-2 gap-8">
            <FormTextInput
              label={'First Name'}
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              placeholder="Enter First Name"
              formErrors={formErrors}
              autoComplete='none'
            />

            <FormTextInput
              label={'Last Name'}
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              placeholder="Enter Last Name"
              formErrors={formErrors}
              autoComplete='none'
            />
          </div>

          <div className="grid justift-items-start grid-cols-2 gap-8">
            <FormTextInput
              label={'Email'}
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter Email"
              formErrors={formErrors}
              autoComplete='none'
            />

            <FormTextInput
              label={'Phone Number'}
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              placeholder="Enter Phone Number"
              formErrors={formErrors}
              autoComplete='none'
            />
          </div>

          <FormTextInput
            label={'Address 1'}
            name="address_1"
            value={formData.address_1}
            onChange={handleChange}
            placeholder="Enter Address"
            formErrors={formErrors}
            autoComplete='none'
          />

          <FormTextInput
            label={'Address (continued)'}
            name="address_2"
            value={formData.address_2}
            onChange={handleChange}
            placeholder="Enter Address (continued)"
            formErrors={formErrors}
            autoComplete='none'
          />

          <div className="grid justift-items-start grid-cols-3 gap-8">
            <FormTextInput
              label={'City'}
              name="city"
              value={formData.city}
              onChange={handleChange}
              placeholder="Enter City"
              formErrors={formErrors}
              autoComplete='none'
            />

            <FormStatesSelectBox
              state_id={formData.state_id}
              required={false}
              formErrors={formErrors}
              onChange={handleChange}
            />

            <FormTextInput
              label={'Zip'}
              name="zip"
              value={formData.zip}
              onChange={handleChange}
              placeholder="Enter Zip Code"
              formErrors={formErrors}
              autoComplete={'off'}
            />
          </div>

          <div className="grid justift-items-start grid-cols-3 gap-8">
            <FormSelectBox
              label={'User Type'}
              name="user_type"
              defaultValue={formData.user_type}
              valueField={'id'}
              keyField={'name'}
              zeroOption={'Select a User Type'}
              onChange={handleChange}
              formErrors={formErrors}
              data={user_types}
              useDefault={true}
            />

            <FormSelectBox
              label={'Role'}
              name="role"
              defaultValue={formData.role}
              valueField={'id'}
              keyField={'name'}
              zeroOption={'Select a Role'}
              onChange={handleChange}
              formErrors={formErrors}
              data={roles}
              useDefault={true}
            />

            <FormSelectBox
              label={'Status'}
              name="status"
              defaultValue={formData.status}
              valueField={'id'}
              keyField={'name'}
              zeroOption={'Select a Status'}
              onChange={handleChange}
              formErrors={formErrors}
              data={statuses}
              useDefault={true}
            />
          </div>

          <h3 className='text-x1 text-gray-800 text-semibold pt-4'>Pay Roll Information</h3>
          <div className="grid justift-items-start grid-cols-2 gap-2">
              <FormDatePicker
                label={'Hire Date'}
                name="hire_date"
                onChange={(e) => handlePayRollChange({ name: 'hire_date', value: e })}
                placeholder="Select a Hire Date"
                value={formData.payroll_record_attributes.hire_date}
                horizontal={false}
              />

              <VendorSelectBox
                label={'Vendor'}
                name={'vendor_id'}
                value={formData.payroll_record_attributes.vendor_id}
                onChange={handlePayRollChange}
                useTarget={false}
              />
          </div>

          <div className="grid justift-items-start grid-cols-2 gap-2">
              <FormTextInput
                label={'Pay Rate %'}
                name="pay_rate"
                value={formData.payroll_record_attributes.pay_rate}
                onChange={handlePayRollChange}
                placeholder="Enter a Pay Rate (%)"
                disabled={showFor1099}
                formErrors={formErrors}
                autoComplete={'off'}
              />

              <FormTextInput
                label={'Payweek Offset'}
                name="pay_week_offset"
                value={formData.payroll_record_attributes.pay_week_offset}
                onChange={handlePayRollChange}
                placeholder="Enter a Weekly offset"
                formErrors={formErrors}
                autoComplete={'off'}
              />
          </div>


          <div className="grid justift-items-start grid-cols-2 gap-2">
              <FormTextInput
                label={'Hourly Rate'}
                name="hourly_rate"
                value={formData.payroll_record_attributes.hourly_rate}
                onChange={handlePayRollChange}
                placeholder="Enter a Hourly Rate"
                formErrors={formErrors}
                disabled={!showFor1099}
                autoComplete={'off'}
              />

              <FormTextInput
                label={`Weekly Salary ${showFor1099}`}
                name="weekly_salary"
                value={formData.payroll_record_attributes.weekly_salary}
                onChange={handlePayRollChange}
                placeholder="Enter a Weekly Salary"
                formErrors={formErrors}
                disabled={!showFor1099}
                autoComplete={'off'}
              />
          </div>

          <div className="grid justift-items-start grid-cols-2 gap-2">
              <FormTextInput
                label={'Payroll Id'}
                name="external_payroll_id"
                value={formData.payroll_record_attributes.external_payroll_id}
                onChange={handlePayRollChange}
                placeholder="Enter a Payroll Id"
                formErrors={formErrors}
                autoComplete={'off'}
              />
              <div className="flex items-center mt-5">
                <input
                  id="tax_form"
                  name="tax_form"
                  type="checkbox"
                  defaultChecked={formData.payroll_record_attributes.tax_form}
                  onChange={(e) => {
                    setShowFor1099(e.target.checked);
                    handlePayRollChange(e);
                  }}
                  className="border border-gray-300 ml-3 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />

                <label htmlFor="tax_form" className="ms-2 text-sm font-medium text-gray-700">1099</label>
              </div>
          </div>

          <div className="grid justift-items-start grid-cols-2 gap-8 pt-10">
            <FormTextInput
              label={'Password'}
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter a Password"
              formErrors={formErrors}
              autoComplete='new-password'
              typeOverride="password"
            />

            <FormTextInput
              label={'Password Confirmation'}
              name="password_confirmation"
              value={formData.password_confirmation}
              onChange={handleChange}
              placeholder="Confirm your Password"
              formErrors={formErrors}
              autoComplete='new-password'
              typeOverride="password"
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Create User
            </button>
          </div>
        </TosForm>
      </div>
    </>
    );
};

export default CreateUser;
