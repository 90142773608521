import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../../utils/ApiTools';

const initialState = {
  types: [],
  loading: false,
};

export const fetchInventoryTypes = createAsyncThunk(
  'inventories/all',
  async () => {
    const msoId = localStorage.getItem('msoId');

    const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/inventory_types`;

    return fetchWithAuth(url, {});
  }
);

const inventoryTypesSlice = createSlice({
  name: 'inventoryTypes',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(fetchInventoryTypes.pending, (state) => {
      state.success = false
      state.error = false;
      state.loading = true;
    })
    .addCase(fetchInventoryTypes.fulfilled, (state, action) => {
      state.loading = false;
      state.types = action.payload.data;
      state.error = null;
    })
    .addCase(fetchInventoryTypes.rejected, (state, action) => {
      state.loading = false;
      state.types = [];
      state.error = action.error.message;
    });
  },
});

export default inventoryTypesSlice.reducer;
