import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import TosDataTable from 'components/TosDataTable';
import { selectAuthUser } from '../../features/auth/authUserSlice';
import 'tailwindcss/tailwind.css';
import HeaderTitleBar from 'components/HeaderTitleBar';
import { fetchCompanies } from '../../features/companies/companiesSlice';
import SelectBox from '../fields/SelectBox';
import { titlize } from 'utils/TextTools';

import {
  fetchJobRoutes,
  clearJobRoutes,
  setPageSize,
  setSelectedCompanyId,
  setSearchQuery
} from '../../features/jobRoutes/jobRouteFilterSlice';
import { getValue } from '@testing-library/user-event/dist/utils';

const Jobroutes = () => {
  const navigate = useNavigate();
  const authUser = useSelector(selectAuthUser);
  const authUserRole = authUser.role;

  const StatusText = ({ field, row }) => {
    return titlize(row.original[field]);
  }

  const DateAdapter = ({ field, row }) => {
    if (row.original[field] === null) return;
    console.log('DATE', row.original[field])
    return row.original[field].toString();
  }

  const JobLink = ({ field, row }) => {
    return <Link
      to={`/billing/job_details/${row.original.id}`}
      onClick={() => { navigate( `/technicians/${row.original.id}` ) }}
      className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
        {row.original[field]}
      </Link>;
  }

  const columns = useMemo(() => {
    if (localStorage.getItem('msoId').toString() == '4') {
      return [
        {
          header: 'Account Number',
          accessorKey: 'account_number',
          cell: ({ getValue, row }) => <JobLink field={'account_number'} value={getValue()} row={row} />
        },
        { header: 'Job Number', accessorKey: 'job_number' },
        { header: 'Technician', accessorKey: 'user.full_name' },
        {
          header: 'Status',
          accessorKey: 'status',
          cell: ({ getValue, row }) => <StatusText field={'status'} value={getValue()} row={row} />
        },
        { header: 'Area', accessorKey: 'area.name' },
        { header: 'Tech ID', accessorKey: 'tech_id' },
        { header: 'HHC Passed', accessorKey: 'passed' },
        { header: 'Job Type', accessorKey: 'job_type' },
        {
          header: 'Job Date',
          accessorKey: 'job_date',
          cell: ({ getValue, row}) => <DateAdapter field={'job_date'} value={getValue()} row={row} />
        },
      ]
    } else {
      return [
        {
          header: 'Job Number',
          accessorKey: 'job_number',
          cell: ({ getValue, row }) => <JobLink field={'job_number'} value={getValue()} row={row} />
        },
        { header: 'Technician', accessorKey: 'user.full_name' },
        {
          header: 'Status',
          accessorKey: 'status',
          cell: ({ getValue, row }) => <StatusText field={'status'} value={getValue()} row={row} />
        },
        { header: 'Area', accessorKey: 'area.name' },
        { header: 'Tech ID', accessorKey: 'tech_id' },
        { header: 'Account Number', accessorKey: 'account_number' },
        { header: 'Job Type', accessorKey: 'job_type' },
        { header: 'Job Date', accessorKey: 'job_date' },
      ]
    }
  }, []);

  const dispatch = useDispatch();

  const {
    data,
    loading,
    pageCount,
    totalItems,
    pageSize,
    selectedCompanyId,
    selectedAreaId,
    selectedUserId,
    searchQuery
  } = useSelector((state) => state.jobRouteFilter);

  const { companyCollection } = useSelector((state) => state.companies);
  const [pageIndex, setPageIndex] = useState(0);
  const [sorting, setSorting] = useState([{ id: 'id', desc: false, selected: true }]);
  const [sortOverride, setSortOverride] = useState(true);

  const canSelectCompany = () => {
    return /admin|mso_user/.test(authUserRole);
  }

  const fetchCompaniesData = useCallback(() => {
    dispatch(fetchCompanies({ page: 1, per: 9999, navigate }));
  }, [ dispatch, navigate]);

  useEffect(() => {
    if (!canSelectCompany()) return;

    fetchCompaniesData();
  }, [ fetchCompaniesData, dispatch ])

  const handleSelectedCompanyId = (value) => {
    /* TODO - REMOVE THIS LATER
    */
    localStorage.setItem('companyId', value);
    dispatch(setSelectedCompanyId(value));
  };

  const fetchJobRoutesData = useCallback(() => {
    dispatch(fetchJobRoutes({
      companyId: selectedCompanyId,
      areaId: selectedAreaId,
      userId: selectedUserId,
      page: pageIndex + 1,
      per: pageSize,
      search: searchQuery,
      sorting
    }));
  }, [dispatch, selectedCompanyId, selectedAreaId, selectedUserId, pageIndex, pageSize, searchQuery, sorting]);

  useEffect(() => {
    if (!canSelectCompany()) {
      dispatch(setSelectedCompanyId(authUser.company_id));
    }

    const canViewWithoutCompany = (authUser.role == 'administrator' || authUser.role == 'mso_user')
    if (canViewWithoutCompany) {
      fetchJobRoutesData()
      return;
    }

    if (!selectedCompanyId) return;

    if (!selectedCompanyId) {
      dispatch(clearJobRoutes());
    } else {
      fetchJobRoutesData();
    }
  }, [selectedCompanyId, fetchJobRoutesData, dispatch]);

  const handlePageChange = (newPageIndex, newPageSize) => {
    setPageIndex(newPageIndex);
    if (newPageSize !== pageSize) dispatch(setPageSize(newPageSize));
  };

  const handleSortingChange = (newSorting) => {
    setSorting(newSorting);
    setSortOverride(!sortOverride);
  };

  const handleSearchChange = (newSearchQuery) => {
    dispatch(setSearchQuery(newSearchQuery));
    setPageIndex(0);
  };

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title='Job Routes'
          showBtn={false}
        />
        {canSelectCompany() && (
          <div className="mt-4">
            <SelectBox
              data={companyCollection}
              defaultValue={selectedCompanyId}
              onChange={handleSelectedCompanyId}
              valueField={'id'}
              keyField={'name'}
              zeroOption={'Select a Company (All)'}
            />
        </div>
        )}
      </div>

      <TosDataTable
        columns={columns}
        data={data}
        pageCount={pageCount}
        totalItems={totalItems}
        pageIndex={pageIndex}
        pageSize={pageSize}
        sorting={sorting}
        loading={loading}
        onPageChange={handlePageChange}
        onPageSizeChange={(size) => handlePageChange(pageIndex, size)}
        onSortingChange={handleSortingChange}
        onSearchChange={handleSearchChange}
      />
    </>
  );
};

export default Jobroutes;