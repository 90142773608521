import React, { useState } from 'react';

const BillingUploader = (props) => {
  const token = localStorage.getItem('authToken');
  const { bucket, label } = props;
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const msoId = localStorage.getItem('msoId');

  const handleFileChange = (event) => {
    setUploading(false);
    setUploaded(false);
    setMessage('');
    setErrorMessage('');
    setProgress(0);
    setFile(event.target.files[0]);
  };

  const getPresignedUrl = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/billing/presigned_url`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          billing: {
            file_name: file.name,
            file_type: file.type,
            bucket: bucket,
          },
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to get presigned URL');
      }

      return await response.json();
    } catch (error) {
      setErrorMessage('Error getting presigned URL');
      console.error(error);
      return null;
    }
  };

  const notifyBackend = async (fileKey) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/billing/notify_upload_complete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          billing: {
            file_key: fileKey,
            bucket: bucket,
          },
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to notify backend');
      }

      setMessage(`File uploaded successfully.  Please check your email.`);
    } catch (error) {
      setErrorMessage('Error notifying backend about upload');
      console.error(error);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setErrorMessage('Please select a file first.');
      return;
    }

    setUploading(true);
    setProgress(0);

    const presignedData = await getPresignedUrl();
    if (!presignedData) return;

    const { url, key } = presignedData;

    try {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url, true);
      xhr.setRequestHeader('Content-Type', file.type);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          setProgress(Math.round((event.loaded * 100) / event.total));
        }
      };

      xhr.onload = async () => {
        if (xhr.status === 200) {
          await notifyBackend(key);
        } else {
          throw new Error('Failed to upload file to S3');
        }
      };

      xhr.onerror = () => {
        setErrorMessage('An error occurred during the upload.');
        console.log('An error occurred during the upload.');
      };

      xhr.send(file);
    } catch (error) {
      console.error(error);
      setErrorMessage('An error occurred during the upload.');
    } finally {
      setUploading(false);
      setUploaded(true);
      setFile(null);
    }
  };

  return (
    <div className="pb-6 w-full">
      <label className="block text-gray-700 text-lg mb-1">{label}</label>
      <p className="text-xs text-gray-600 mb-4">Select XML or XLSX File</p>
      {errorMessage && <span className="text-sm text-red-600 mb-2 block">{errorMessage}</span>}
      <div className="flex items-center">
        <input
          type="file"
          onChange={handleFileChange}
          className="hidden"
          id={`file-${bucket}`}
          accept=".xml, .xlsx"
        />
        <label
          htmlFor={`file-${bucket}`}
          className="py-1 px-2 mr-1 flex items-center bg-gray-500 text-white font-semi rounded-sm hover:bg-gray-700 cursor-pointer"
        >
          Choose file
        </label>
        <button
          onClick={handleUpload}
          disabled={!file || uploading}
          className={`flex items-center bg-blue-500 text-white font-semi py-1 px-3 rounded-sm hover:bg-blue-700 ${
            uploading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-300'
          }`}
        >
          {uploading ? 'Uploading...' : 'Upload'}
        </button>
      </div>
      {(uploading || uploaded) && (
        <div className="mt-4 mr-6 mb-2 w-60 bg-gray-200 rounded-full h-4 dark:bg-gray-700">
          <div
            className="bg-blue-500 h-4 rounded-full"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      )}
      {message && <span className="text-sm text-blue-600 mb-2 block">{message}</span>}
      {file && !uploading && <p className="mt-2 ml-2 text-sm text-gray-800">{file.name}</p>}
    </div>
  );
};

export default BillingUploader;