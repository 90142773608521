import React, { useState, useCallback, useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'tailwindcss/tailwind.css';
import { Link } from 'react-router-dom';
import FormDatePicker from 'components/formHelpers/FormDatePicker';
import AreaSelectBox from 'components/dropdowns/AreaSelectBox';
import { fetchCompanies } from '../../features/companies/companiesSlice';
import { formatDateToMMDDYYYY } from 'utils/TextTools';
import HeaderTitleBar from 'components/HeaderTitleBar';
import FormSubmissionResult from 'components/formHelpers/FormSubmissionResult';
import SelectBox from '../fields/SelectBox';
import { selectAuthUser } from '../../features/auth/authUserSlice';
import { useNavigate } from 'react-router-dom';
import { billingComparison } from '../../features/billing/billingSlice';
import moment from 'moment';

const BillingComparison = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = useSelector(selectAuthUser);
  const authUserRole = authUser.role;

  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndtDate, setSelectedEndtDate] = useState(new Date());
  const [selectedAreaId, setSelectedAreaId] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const { comparisonCollection, success, error } = useSelector((state) => state.billing)
  const { companyCollection } = useSelector((state) => state.companies);


  const canSelectCompany = () => {
    return /admin|mso_user/.test(authUserRole);
  }

  const fetchCompaniesData = useCallback(() => {
    dispatch(fetchCompanies({ page: 1, per: 9999, navigate }));
  }, [ dispatch, navigate]);

  useEffect(() => {
    if (!canSelectCompany()) return;

    fetchCompaniesData();
  }, [ fetchCompaniesData, dispatch ])

  const handleStartChange = (date) => {
    setSelectedStartDate(date);
  }

  const handleEndChange = (date) => {
    setSelectedEndtDate(date);
  }

  const handleAreaChange = (e) => {
    const { value } = e.target;
    setSelectedAreaId(value);
  }

  const handleSelectedCompanyId = (value) => {
   // dispatch(setSelectedCompanyId(value));
  };

  const formatDate = (date) => {
    if(date === null) return ""
    return moment(date).format('MM/DD/YYYY');
  };

  const handleView = (e) => {
    e.preventDefault();

    if (parseInt(selectedAreaId) === 0 || typeof(selectedAreaId) === 'undefined') {
      return;
    }

    dispatch(billingComparison({
      startDate: formatDateToMMDDYYYY(selectedStartDate),
      endDate: formatDateToMMDDYYYY(selectedEndtDate),
      areaId: selectedAreaId
    }));
  }

  const comparisonCollectionLoaded = () => {
    return comparisonCollection && typeof(comparisonCollection) != 'undefined'
  }

  const JobLink = ({id, jobNumber}) => {
    return <Link
      to={`/billing/job_details/${id}`}
      onClick={() => { navigate( `/billing/job_details/${id}` ) }}
      className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
        {jobNumber}
      </Link>;
  }

  const renderComparison = () => {
    const pendingBillList = comparisonCollection.pending_routes;
    const missingBillList = comparisonCollection.missing_routes;
    const completeBillList = comparisonCollection.complete_routes;
    const noBillInfoBillList = comparisonCollection.no_bill_info_routes;

    return (
      (pendingBillList || missingBillList || completeBillList || noBillInfoBillList) && (
        <div className="container mx-auto">
          <div className="grid grid-cols-4 gap-4">
            <div className="text-center">
              <h4 className="font-bold text-lg">Pending</h4>
            </div>
            <div className="text-center">
              <h4 className="font-bold text-lg">Missing</h4>
            </div>
            <div className="text-center">
              <h4 className="font-bold text-lg">Complete</h4>
            </div>
            <div className="text-center">
              <h4 className="font-bold text-lg">No Bill Info</h4>
            </div>
          </div>

          <hr className="my-4" />

          <div className="grid grid-cols-4 gap-4">
            {/* Pending Bills */}
            <div className="space-y-4">
              {pendingBillList?.map((pending) => (
                <div key={pending.id} className="border p-4 rounded-md">
                  <JobLink id={pending.id} jobNumber={pending.job_number} />
                  <p>{formatDate(pending.job_date)}</p>
                  <p>{pending.tech_id}</p>
                </div>
              ))}
            </div>

            {/* Missing Bills */}
            <div className="space-y-4">
              {missingBillList?.map((missing) => (
                <div key={missing.id} className="border p-4 rounded-md">
                  <JobLink id={missing.id} jobNumber={missing.job_number} />
                  <p>{formatDate(missing.job_date)}</p>
                  <p>{missing.tech_id}</p>
                </div>
              ))}
            </div>

            {/* Complete Bills */}
            <div className="space-y-4">
              {completeBillList?.map((complete) => (
                <div key={complete.id} className="border p-4 rounded-md">
                  <JobLink id={complete.id} jobNumber={complete.job_number} />
                  <p>{formatDate(complete.job_date)}</p>
                  <p>{complete.tech_id}</p>
                </div>
              ))}
            </div>

            {/* No Bill Info */}
            <div className="space-y-4">
              {noBillInfoBillList?.map((nobillinfo) => (
                <div key={nobillinfo.id} className="border p-4 rounded-md">
                  <JobLink id={nobillinfo.id} jobNumber={nobillinfo.job_number} />
                  <p>{formatDate(nobillinfo.job_date)}</p>
                  <p>{nobillinfo.tech_id}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
    ))
  }

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title='Billing Comparison'
          showBtn={false}
        />
        {canSelectCompany() && (
          <div className="mt-4">
            <SelectBox
            data={companyCollection}
            defaultValue={selectedCompanyId}
            onChange={handleSelectedCompanyId}
            valueField={'id'}
            keyField={'name'}
            zeroOption={'Select a Company (All)'}
            />
          </div>
        )}
      </div>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <FormSubmissionResult
          successText={`Export Created`}
          error={error}
        />

        <form onSubmit={handleView} className="space-y-0 mt-6 w-1/2">
          <div className="grid justift-items-start grid-cols-2 gap-4">
            <AreaSelectBox
              label={'Area'}
              name={'area_id'}
              value={selectedAreaId}
              onChange={handleAreaChange}
              useTarget={false}
              horizontal={true}
            />
          </div>
          <div className="grid justift-items-start grid-cols-2 gap-4">
            <FormDatePicker
              label={'Start Date'}
              name="start_date"
              onChange={handleStartChange}
              placeholder="Enter a Start Date"
              value={selectedStartDate}
              horizontal={true}
            />
          </div>
          <div className="grid justift-items-start grid-cols-2 gap-4">
            <FormDatePicker
              label={'End Date'}
              name="end_date"
              onChange={handleEndChange}
              placeholder="Enter an End Date"
              value={selectedEndtDate}
              horizontal={true}
            />
          </div>
          <div className="flex md:items-center mb-6 space-x-1">
            <button className="bg-blue-600 text-white px-4 p-1">View Comparison</button>
          </div>
        </form>
      </div>

      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        {comparisonCollectionLoaded() && renderComparison()}
      </div>
    </>
  );
};

export default BillingComparison