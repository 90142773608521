import { createSlice } from '@reduxjs/toolkit';
import { getSubDomain } from '../../utils/NetworkTools';

const subdomainSlice = createSlice({
  name: 'subdomain',
  initialState: {
    value: getSubDomain(),
  },
  reducers: {
    setSubdomain: (state, action) => {
      localStorage.setItem('subdomain', action.payload);
      state.value = action.payload;
    },
  },
});

export const { setSubdomain } = subdomainSlice.actions;
export const selectSubdomain = (state) => localStorage.getItem('subdomain');
export default subdomainSlice.reducer;